import { Box, Grid, IconButton } from "@material-ui/core";
import { Close, RemoveRedEye } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useState, useRef, useEffect, useMemo } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
    createStudent,
    deleteStudentById,
    getAllStudent,
    updateStudentById,
    importStudent,
    getAllStudentExcel,
} from "../../../apis/studentApi";
import { uploadImageFile } from "../../../apis/uploadApi";
import { swalCondition } from "../../../utils/swal";
import { SwalFileImport, difference, exportExcelUtimate, xlsxFileReader } from "../../../utils/xlsx";
import { getOptionsTableApp } from "../../helpers/useOption";
import StudentForm from "./StudentForm";
import { columnTableStudents, initialValuesStudent } from "./helpers/studentManageOption";
import StudentSearch from "./StudentSearch";
import { getMenuOption } from "../../../apis/reportApi";
import PreviewImport from "../../componentsUtils/PreviewImport";
import { isEmail, validateDate } from "../../../utils/validation";
import { getBasicOfBakingName, getSocialPermissionName, getToKnowFromName } from "../StudentProfile/helpers/optionName";
import exportToExcel from "../../../utils/exportToExcel";
import ModalBoostrap from "../../componentsUtils/ModalBoostrap";
import { useFilter } from "../../../hooks/useFilter";

export default function StudentContent() {
    const history = useHistory();
    const { filter, setFilter, clearFilter } = useFilter();

    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState({ ...getOptionsTableApp, pageSize: 100 });
    const [isExport, setIsExport] = useState(false);
    const [searchData, setSearchData] = useState({
        course: "",
        menu: "",
        search: "",
        status: "",
    });
    const [isImport, setIsImport] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [fileImport, setFileImport] = useState({
        name: "",
        data: [],
        columns: [],
        author: "",
    });
    const [option, setOption] = useState({
        menu: [],
        course: [],
    });

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // close create form modal
    const handleCloseCreateFormModal = () => {
        setModalForm(false);
    };

    // open create form modal
    const handleOpenCreateFormModal = () => {
        setModalForm(true);
    };

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    search: searchData?.search || "",
                    menu: searchData?.menu || "",
                    courseId: searchData?.course || "",
                    status: searchData?.status || "",
                    order: query?.orderBy?.field || "id",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                };
                const res = await getAllStudent(queryParams, controllerRef.current?.signal);
                if (res) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "id",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [searchData],
    );

    // create student logic
    const handleCreateStudent = useCallback(async values => {
        try {
            const createBody = {
                studentFirstname: values?.fname,
                studentLastname: values?.lname,
                studentNickname: values?.nickname,
                studentClassof: values?.classOf,
                studentEmail: values?.email,
                studentTel: values?.tel,
                studentLineid: values?.lineId,
                studentBirthday: values?.birthday,
                studentAddress: values?.address,
                studentProvince: values?.province,
                studentCountry: values?.country,
                studentExpertise: values?.expertise,
                studentSizeshirt: values?.sizeApron,
                studentNote: values?.studentNote,
                objective: values?.learnObjt,
                funnel: values?.knowChannels,
                social: values?.allowSocial,
            };
            if (values?.image?.file) {
                createBody.studentImagePath = await uploadImageFile(values?.image?.file, controllerRef.current?.signal);
            }
            const isCreate = await createStudent(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete student logic
    const handleDeleteStudent = useCallback(async studentt => {
        try {
            const bool = await swalCondition(
                "Confirm delete ?",
                `${studentt?.studentFirstname} ${studentt?.studentLastname}`,
                {
                    icon: "warning",
                },
            );
            if (bool) {
                const isDelete = await deleteStudentById(studentt?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // export excel
    const handleExportExcel = useCallback(async () => {
        setIsExport(true);
        try {
            const queryParams = {
                search: searchData?.search || "",
                menu: searchData?.menu || "",
                courseId: searchData?.course || "",
                status: searchData?.status || "",
                order: tableOptions?.order || "id",
                direction: tableOptions?.direction || "desc",
            };
            const res = await getAllStudentExcel(queryParams, controllerRef.current?.signal);
            if (res) {
                const data = res?.data?.map(item => ({
                    Firstname: item?.studentFirstname || "",
                    Lastname: item?.studentLastname || "",
                    Nickname: item?.studentNickname || "",
                    "Class of #": item?.studentClassof || "",
                    Email: item?.studentEmail || "",
                    Tel: item?.studentTel || "",
                    "Line ID": item?.studentLineid || "",
                    "Birth Date": item?.studentBirthday ? moment(item?.studentBirthday).format("DD/MM/YYYY") : "",
                    Address: item?.studentAddress || "",
                    Province: item?.studentProvince || "",
                    Country: item?.studentCountry || "",
                    "Level of expertise": item?.studentExpertise ? getBasicOfBakingName(item?.studentExpertise) : "",
                    "Size of Chef Shirt": item?.studentSizeshirt || "",
                    "Student Note": item?.studentNote || "",
                    "Learning Purpose": item?.objective || "",
                    "Get to know from": item?.funnel ? getToKnowFromName(item?.funnel) : "",
                    "Social Media Permission": item?.social ? getSocialPermissionName(item?.social) : "",
                }));
                if (!data?.length) return false;
                exportToExcel(data, { fileName: "SCA_students" });
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsExport(false);
        }
    }, [searchData, tableOptions]);

    // export template
    const handleExportTemplate = useCallback(() => {
        const data = [
            {
                "First name": "Ploy",
                "Last name": "IVB",
                "Nick name": "Ploy",
                "Class of": "2023",
                Email: "ploy@abc.com",
                Tel: "0961223654",
                "Line ID": "Ploy",
                "Birth Date": "01/01/2000",
                Address: "123/123",
                Province: "BKK",
                Country: "TH",
                "Level of expertise": "1",
                "Size of Chef Shirt": "M",
                "Student Note": "อยากเรียนมากเลยค่ะ",
                "Learning Purpose": "Open Bakery Shop",
                "Get to know from": "2",
                "Social Media Permission": "1",
                Note: "Booking",
            },
        ];
        const studentData = [
            {
                "Column Name": "First Name",
                "Data Type": "varchar(255) (NOT NULL)",
                Description: "ชื่อของ student",
                "Example Data": "Ploy",
            },
            {
                "Column Name": "Last Name",
                "Data Type": "varchar(255) (NOT NULL)",
                Description: "นามสกุล student",
                "Example Data": "IVB",
            },
            {
                "Column Name": "Nick Name",
                "Data Type": "varchar(255)",
                Description: "ชื่อเล่นของ student",
                "Example Data": "Ploy",
            },
            {
                "Column Name": "Class of",
                "Data Type": "int(11)",
                Description: "รุ่นที่",
                "Example Data": "2023",
            },
            {
                "Column Name": "Email",
                "Data Type": "varchar(255)",
                Description: "email ของ student",
                "Example Data": "ploy@abc.com",
            },
            {
                "Column Name": "Tel",
                "Data Type": "varchar(10) (NOT NULL)",
                Description: "tel ของ student",
                "Example Data": "0961223654",
            },
            {
                "Column Name": "Line ID",
                "Data Type": "varchar(255)",
                Description: "lind id ของ student",
                "Example Data": "Ploy",
            },
            {
                "Column Name": "Birth Date",
                "Data Type": "date",
                Description: "วันเกิดของ student",
                "Example Data": "01/01/2000",
            },
            {
                "Column Name": "Address",
                "Data Type": "longtext",
                Description: "ที่อยู่",
                "Example Data": "123/123",
            },
            {
                "Column Name": "Province",
                "Data Type": "varchar(255)",
                Description: "จังหวัด",
                "Example Data": "BKK",
            },
            {
                "Column Name": "Country",
                "Data Type": "varchar(255)",
                Description: "ประเทศ",
                "Example Data": "TH",
            },
            {
                "Column Name": "Level of expertise",
                "Data Type": "enum('1','2','3','4')",
                Description: "พื้นฐานในการทำเบเกอรี่ 1=Never bake before 2=Beginner 3=Intermediate 4=Expert",
                "Example Data": "1",
            },
            {
                "Column Name": "Size of Chef Shirt",
                "Data Type": "varchar(255)",
                Description: "size เสื้อ student",
                "Example Data": "M",
            },
            {
                "Column Name": "Student Note",
                "Data Type": "varchar(255)",
                Description: "โน๊ตของนักเรียน",
                "Example Data": "อยากเรียนมากเลยค่ะ",
            },
            {
                "Column Name": "Learning Purpose",
                "Data Type": "longtext",
                Description: "จุดประสงค์การเรียน",
                "Example Data": "Open Bakery Shop",
            },
            {
                "Column Name": "Get to know from",
                "Data Type": "enum('1','2','3','4','5','6','7')",
                Description:
                    "รู้จักผ่านช่องทาง 1 = facebook 2=Google 3=instagram 4=ticktok 5=youtube 6=เพื่อนแนะนำ 7=อื่นๆ",
                "Example Data": "2",
            },
            {
                "Column Name": "Social Media Permission",
                "Data Type": "enum('0','1')",
                Description: "อนุญาตให้ลง social 0=no 1=yes",
                "Example Data": "1",
            },
            {
                "Column Name": "Note",
                "Data Type": "longtext",
                Description: "Note message",
                "Example Data": "Booking",
            },
        ];
        const allExport = [data, studentData];
        const allFileName = ["Student Template", "Student Data"];
        const colWidth = [
            { width: 12 },
            { width: 12 },
            { width: 12 },
            { width: 10 },
            { width: 15 },
            { width: 12 },
            { width: 15 },
            { width: 12 },
            { width: 20 },
            { width: 10 },
            { width: 10 },
            { width: 15 },
            { width: 15 },
            { width: 18 },
            { width: 20 },
            { width: 15 },
            { width: 15 },
            { width: 20 },
        ];
        exportExcelUtimate(allExport, allFileName, colWidth);
    }, []);

    // open import student modal
    const handleImportStudent = useCallback(async () => {
        const { file } = await SwalFileImport();
        if (file) {
            const { data, header, fileName, author, lastAuthor, error } = await xlsxFileReader(file);
            if (!error) {
                if (data?.length > 12000) {
                    return swalCondition("Error !!!", "Maximum Rows is 12,000", {
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }

                // expect 18 column
                const columnLength = 18;
                if (header?.length !== columnLength) {
                    return swalCondition(
                        "Error !!!",
                        `Import Student Expect ${columnLength} Columns But Got ${header?.length}`,
                        {
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        },
                    );
                }
                const columns = [
                    "First name",
                    "Last name",
                    "Nick name",
                    "Class of",
                    "Email",
                    "Tel",
                    "Line ID",
                    "Birth Date",
                    "Address",
                    "Province",
                    "Country",
                    "Level of expertise",
                    "Size of Chef Shirt",
                    "Student Note",
                    "Learning Purpose",
                    "Get to know from",
                    "Social Media Permission",
                    "Note",
                ];

                const incorrectColumns = Array?.from(difference(header, columns));
                if (incorrectColumns?.length > 0) {
                    return swalCondition("Error !!!", "Incorrect Form Input, Please Download Templete", {
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }

                setIsShowModal(true);

                setFileImport({
                    data,
                    columns: header?.map(data => {
                        return { title: data, field: data };
                    }),
                    name: fileName,
                    author: author || lastAuthor,
                });
            }
        }
    }, []);

    // handle save import student
    const handleSaveImportStudent = useCallback(async excel => {
        setIsImport(true);
        try {
            const data = [];
            const emptyFirstname = [];
            const emptyLastname = [];
            const invalidEmails = [];
            const emptyTels = [];
            const invalidTels = [];
            const invalidBirthDates = [];
            for (const row of excel) {
                if (!row["First name"]) {
                    emptyFirstname.push(row?.tableData?.id + 2);
                }
                if (!row["Last name"]) {
                    emptyLastname.push(row?.tableData?.id + 2);
                }
                if (!row?.Tel) {
                    emptyTels.push(row?.tableData?.id + 2);
                }
                if (row?.Tel && row?.Tel?.length > 15) {
                    invalidTels.push(row?.tableData?.id + 2);
                }
                if (row?.Email && !isEmail(row?.Email)) {
                    invalidEmails.push(row?.tableData?.id + 2);
                }
                if (row["Birth Date"] && !validateDate(row["Birth Date"])) {
                    invalidBirthDates.push(row?.tableData?.id + 2);
                }

                data.push({
                    studentFirstname: row["First name"],
                    studentLastname: row["Last name"],
                    studentNickname: row["Nick name"] || "",
                    studentClassof: row["Class of"] ? Number(row["Class of"]) : 0,
                    studentEmail: row?.Email || null,
                    studentTel: row?.Tel || "",
                    studentLineid: row["Line ID"] || "",
                    studentBirthday: row["Birth Date"] ? moment(row["Birth Date"]).format("YYYY-MM-DD") : undefined,
                    studentAddress: row?.Address || "",
                    studentProvince: row?.Province || "",
                    studentCountry: row?.Country || "",
                    studentExpertise: row["Level of expertise"] || undefined,
                    studentSizeshirt: row["Size of Chef Shirt"] || "",
                    studentNote: row["Student Note"] || "",
                    objective: row["Learning Purpose"] || "",
                    note: row?.Note || "",
                    funnel: row["Get to know from"] || undefined,
                    social: row["Social Media Permission"] || undefined,
                });
            }

            // show empty firstname error
            if (emptyLastname?.length) {
                return await swalCondition("Error!!!", `First name cannot be empty. (${emptyFirstname?.join(", ")})`, {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
            }

            // show empty lastname error
            if (emptyLastname?.length) {
                return await swalCondition("Error!!!", `Last name cannot be empty. (${emptyLastname?.join(", ")})`, {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
            }

            // show empty tel error
            if (emptyTels?.length) {
                return await swalCondition("Error!!!", `Phone number cannot be empty. (${emptyTels?.join(", ")})`, {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
            }

            // show invalid tel error
            if (invalidTels?.length) {
                return await swalCondition(
                    "Error!!!",
                    `Phone number must be less than or equal 15 length. (${invalidTels?.join(", ")})`,
                    {
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    },
                );
            }

            // show invalid email error
            if (invalidEmails?.length) {
                return await swalCondition("Error!!!", `Wrong Email Format. (${invalidEmails?.join(", ")})`, {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
            }

            // show invalid birth date error
            if (invalidBirthDates?.length) {
                return await swalCondition("Error!!!", `Wrong Birth Date Format. (${invalidBirthDates?.join(", ")})`, {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
            }

            const isImported = await importStudent({ student: data }, controllerRef?.current?.signal);
            if (isImported) {
                await swalCondition("Successfully !!!", "", {
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
                setIsShowModal(false);
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsImport(false);
        }
    }, []);

    // change status
    const handleChangeStatus = useCallback(async data => {
        try {
            let changeStatus = "0";
            if (data?.obj?.status === "0") {
                changeStatus = "1";
            }
            const isCreate = await updateStudentById(
                data?.obj?.id,
                { status: changeStatus },
                controllerRef.current?.signal,
            );
            if (isCreate) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnTableStudents({ tableOptions, handleChangeStatus }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // fetch filter option
    const fetchOption = useCallback(async () => {
        try {
            const res = await getMenuOption({ type: "4" }, controllerRef?.current?.signal);
            if (res) {
                setOption({
                    menu: res?.menuOption,
                    course: res?.courseOption,
                });
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // run only first render

    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchOption();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filter && Object.entries(filter).length) {
            setSearchData(prev => ({ ...prev, ...filter }));
            clearFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (searchData?.status !== undefined) {
            tableRef.current.onQueryChange({ page: 0 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchData]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mb-3">
                <StudentSearch
                    option={option}
                    importStudent={handleImportStudent}
                    handleExportTemplate={handleExportTemplate}
                    initialValues={searchData}
                    onSubmit={values => {
                        setSearchData(values);
                    }}
                    {...{ isExport, handleExportExcel, handleOpenCreateFormModal, thisMenuPermission }}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        data={fetchData}
                        options={tableOptions}
                        columns={tableColumns}
                        components={{ Container: Box }}
                        actions={[
                            {
                                icon: () => <RemoveRedEye />,
                                iconProps: { className: "action-view" },
                                tooltip: "View",
                                onClick: (_evt, d) => {
                                    setFilter(searchData);
                                    history.push(`/student_management/student_list/${d.id}`);
                                },
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteStudent(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered onHide={handleCloseCreateFormModal}>
                    <Modal.Header>
                        <ModalTitle>Create Student</ModalTitle>
                        <IconButton onClick={handleCloseCreateFormModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <ModalBody>
                        <StudentForm
                            onCancel={handleCloseCreateFormModal}
                            onSubmit={handleCreateStudent}
                            initialValuesStudent={initialValuesStudent}
                        />
                    </ModalBody>
                </Modal>
            </Grid>
            <Grid item xs={12}>
                <ModalBoostrap
                    title="Import Student"
                    size="xl"
                    show={isShowModal}
                    handleClose={() => setIsShowModal(false)}
                    handleSave={() => handleSaveImportStudent(fileImport.data)}
                    isSaveButtonLoading={isImport}
                    showSaveButton>
                    <PreviewImport
                        columns={fileImport.columns}
                        data={fileImport.data}
                        fileName={fileImport.name}
                        author={fileImport.author}
                    />
                </ModalBoostrap>
            </Grid>
        </Grid>
    );
}
