import { Button, FormControl, Grid, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { Add, GetApp, Search } from "@material-ui/icons";
import { Formik } from "formik";
import React from "react";
import SelectApp from "../../componentsUtils/SelectApp";

export default function StudentSearch({
    onSubmit,
    option,
    thisMenuPermission,
    handleOpenCreateFormModal,
    isExport,
    handleExportExcel,
    handleExportTemplate,
    importStudent,
    initialValues,
}) {
    const formikRef = React.useRef();

    React.useEffect(() => {
        if (formikRef.current?.resetForm && initialValues) {
            const newValues = {
                status: "",
                menu: "",
                course: "",
                search: "",
                ...initialValues,
            };
            formikRef.current.resetForm({
                values: newValues,
            });
        }
    }, [initialValues]);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                status: "",
                menu: "",
                course: "",
                search: "",
            }}
            onSubmit={onSubmit}>
            {({ values, setFieldValue, handleSubmit }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={3}>
                                <label className="mb-0 mr-5">{null}</label>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={values?.search}
                                        onChange={value => {
                                            setFieldValue("search", value?.target?.value);
                                            setTimeout(() => {
                                                handleSubmit();
                                            }, 500);
                                        }}
                                        placeholder="Search..."
                                        className="fixed-h-lg"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search className="text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={1}>
                                <label className="mb-0 mr-5">Status : </label>
                                <div className="flex-fill">
                                    <SelectApp
                                        value={values.status}
                                        onChange={value => {
                                            setFieldValue("status", value);
                                            setTimeout(() => {
                                                handleSubmit();
                                            }, 500);
                                        }}
                                        options={[
                                            { value: "1", title: "Active" },
                                            { value: "0", title: "Inactive" },
                                        ]}
                                        placeholder="All"
                                        selectClass="fixed-h-lg"
                                        RenderItem={(option, idx) => (
                                            <MenuItem key={`status${idx}`} value={option.value}>
                                                {" "}
                                                {option.title}{" "}
                                            </MenuItem>
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <label className="mb-0 mr-5">By Menu : </label>
                                <div className="flex-fill">
                                    <SelectApp
                                        value={values.menu}
                                        onChange={value => {
                                            setFieldValue("menu", value);
                                            setTimeout(() => {
                                                handleSubmit();
                                            }, 500);
                                        }}
                                        options={option?.menu}
                                        selectClass="fixed-h-lg"
                                        placeholder="All"
                                        RenderItem={(option, idx) => (
                                            <MenuItem key={`menu${idx}`} value={option.id}>
                                                {option.course_menu_name}
                                            </MenuItem>
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <label className="mb-0 mr-5">By Course : </label>
                                <div className="flex-fill">
                                    <SelectApp
                                        value={values.course}
                                        onChange={value => {
                                            setFieldValue("course", value);
                                            setTimeout(() => {
                                                handleSubmit();
                                            }, 500);
                                        }}
                                        options={option?.course}
                                        selectClass="fixed-h-lg"
                                        placeholder="All"
                                        RenderItem={(option, idx) => (
                                            <MenuItem key={`course${idx}`} value={option.id}>
                                                {option.course_name}
                                            </MenuItem>
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={2}>
                                <label className="mb-0 mr-5">{null}</label>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Button
                                            className="t-btn-primary fixed-h-lg w-100"
                                            size="large"
                                            onClick={() => {
                                                setFieldValue("menu", "");
                                                setFieldValue("search", "");
                                                setFieldValue("status", "");
                                                setFieldValue("course", "");
                                                setTimeout(() => {
                                                    handleSubmit();
                                                }, 500);
                                            }}>
                                            Clear
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {thisMenuPermission?.create && (
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Button
                                        onClick={handleOpenCreateFormModal}
                                        size="large"
                                        className="btn btn-lg t-btn-primary fixed-h-lg w-100">
                                        <Add /> Create Student
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Button
                                        onClick={handleExportTemplate}
                                        size="large"
                                        className="btn btn-lg t-btn-primary fixed-h-lg w-100">
                                        Template
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Button
                                        onClick={importStudent}
                                        size="large"
                                        className="btn btn-lg t-btn-primary fixed-h-lg w-100">
                                        <Add /> Import
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Button
                                        disabled={isExport}
                                        onClick={handleExportExcel}
                                        size="large"
                                        className="btn btn-lg t-btn-primary fixed-h-lg w-100">
                                        {isExport ? "Loading..." : <GetApp style={{ fontSize: 22 }} />}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
