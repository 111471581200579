import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { filterActions } from "../redux/slices/filterSlice";

export function useFilter() {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const filterAllPage = useSelector(state => {
        return state?.filter;
    });

    /**
     * Set data with pathname key.
     */
    const setFilter = useCallback(
        (data = {}) => {
            dispatch(
                filterActions.setFilterData({
                    [pathname]: data,
                }),
            );
        },
        [dispatch, pathname],
    );

    /**
     * Clear data of filter with pathname key.
     */
    const clearFilter = useCallback(() => {
        dispatch(filterActions.setClearData(pathname));
    }, [dispatch, pathname]);

    /**
     * Find filter data with pathname key.
     */
    const memoizeFilterWithPathname = useMemo(() => {
        return filterAllPage?.[pathname];
    }, [filterAllPage, pathname]);

    return {
        filters: filterAllPage,
        filter: memoizeFilterWithPathname,
        setFilter,
        clearFilter,
    };
}
