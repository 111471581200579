import { combineReducers } from "redux";
import { userReducers } from "./slices";
import { menuReducers } from "./slices/menuSlice";
import { mainMenuReducers } from "./slices/mainMenuSlice";
import { filterReducer } from "./slices/filterSlice";

export const rootReducer = combineReducers({
    user: userReducers,
    mainMenu: mainMenuReducers,
    menu: menuReducers,
    filter: filterReducer,
});

export function* rootSaga() {}
