import { createSlice } from "@reduxjs/toolkit";

const initialStateFilter = {};

const filterSlice = createSlice({
    name: "filter",
    initialState: initialStateFilter,
    reducers: {
        setFilterData: (state, { payload }) => {
            Object.assign(state, payload);
            return state;
        },
        setClearData: (state, { payload }) => {
            Object.assign(state, { [payload]: undefined });
            return state;
        },
    },
});

export const { actions: filterActions, reducer: filterReducer } = filterSlice;
export default filterSlice;
